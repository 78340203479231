import React from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../Contexts/AuthProvider';
import { toast } from "react-hot-toast";

const UpdatePassword = () => {
    const { logOut, newPass } = useContext(AuthContext);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm();
    const handleAdduser = (data) => {
            newPass(data.password)
            toast.success('Password Updated');
            logOut()
      .then(() => { })
      .catch(err => console.log(err));
      };
    return (
        <div>
      <form
        onSubmit={handleSubmit(handleAdduser)}
        className="bg-white text-headcolor py-4 px-6"
      >
        <div className="grid grid-cols-1 md:grid-cols2">
          <div className="text-left px-2 my-2">
            <label className="text-headcolor text-sm"> New Password </label> <br />
            <input
              type="text"
              {...register("password", {
                required: "Password is Required",
              })}
              className="bg-white border-[1px] px-4 py-2 rounded-md border-headcolor w-full text-headcolor text-sm focus:outline-none"
            />
      
          </div>

        </div>
        <button
          type="submit"
          className="bg-btncolor px-4 py-2 rounded-md text-white text-sm drop-shadow-lg mt-2 ml-2"
        >
          Update Password
        </button>
      </form>
    </div>
    );
};

export default UpdatePassword;