import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../Contexts/AuthProvider";
import useToken from "../../hooks/useToken";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const Login = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();
  const { providerLogin, signIn, createUser, updateUser } = useContext(AuthContext);
  const [loginError, setLoginError] = useState("");
  const [loginUserEmail, setLoginUserEmail] = useState("");
  const [token] = useToken(loginUserEmail);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = async (data) => {
    const loginPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`https://jahidserver.vercel.app/userCount?user=${data.email}`);
        const userCountResult = await response.json();

        if (userCountResult.total === 1) {
          try {
            await createUser(userCountResult.name.email, userCountResult.name.password);
            const userInfo = { displayName: userCountResult.name.name };
            await updateUser(userInfo);
            reset();
            loginFunc(data.email, data.password)
              .then(resolve)
              .catch(reject);
          } catch (err) {
            reset();
            loginFunc(data.email, data.password)
              .then(resolve)
              .catch(reject);
          }
        } else {
          toast.error('Email and Password incorrect!');
          reset();
          reject(new Error('Email and Password incorrect!'));
        }
      } catch (error) {
        reject(error);
      }
    });

    toast.promise(loginPromise, {
      loading: 'Logging in...',
      success: 'User Logged in Successfully 👌',
      error: 'Email and Password incorrect! 🤯'
    });
  };

  const loginFunc = async (email, password) => {
    try {
      setLoginError("");
      const result = await signIn(email, password);
      const user = result.user;
      setLoginUserEmail(email);
      navigate("/dashboard");
    } catch (error) {
      throw new Error('Email and Password incorrect!');
    }
  };


  return (
    <div className="grid items-center relative w-full h-screen bg-mainbg">
      <div className="w-full md:w-1/4 mx-auto px-4 py-7 bg-white rounded-md text-white">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="grid grid-cols-1">
            <div className="px-2">
              <img src="/login.jpg" alt="" className="w-32 h-32 rounded-full mx-auto object-cover" />
              <h2 className="text-left font-semibold text-lg text-headcolor">Login</h2>
              <small className="font-semibold text-xs text-textcolor">Welcome back! Log in to your account.</small>
            </div>
            <div className="text-left px-2 my-2">
              <label className="text-headcolor font-bold text-sm">Email Address</label> <br />
              <input
                type="email"
                placeholder="john@gmail.com"
                {...register("email", {
                  required: "Email is Required",
                })}
                className="bg-transparent border-[1px] border-gray-400 px-6 py-3 rounded-md  w-full text-textcolor focus:outline-none text-sm"
              />
              {errors.email && (
                <p className="text-red-500">{errors.email.message}</p>
              )}
            </div>
            <div className="text-left px-2 my-2">
              <label className="text-headcolor font-bold text-sm">Password</label> <br />
              <input
                type="password"
                placeholder="**********"
                {...register("password", {
                  required: "Password is Required",
                })}
                className="bg-transparent border-[1px] border-gray-400 px-6 py-3 rounded-md  w-full text-textcolor focus:outline-none text-sm"
              />
              {errors.password && (
                <p className="text-red-500">{errors.password.message}</p>
              )}
            </div>
          </div>
          <div className="px-2 mt-2 text-right">
            <button
              type="submit"
              className="bg-btncolor px-8 py-2 rounded-md text-white font-bold drop-shadow-lg text-sm uppercase"
            >
              Sign in
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default Login;
