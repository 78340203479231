import React from "react";
import { useContext } from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../Contexts/AuthProvider";
import { useState } from "react";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { createUser, updateUser } = useContext(AuthContext);

  const [signUpError, setSignUPError] = useState(false);
  const [createdUserEmail, setCreatedUserEmail] = useState("");
  const [token] = useToken(createdUserEmail);
  const navigate = useNavigate();

  const handleAdduser = (data) => {
    // createUser(data.email, data.password)
    //   .then((result) => {
    //     const user = result.user;
    //     const currentDate = new Date();
    //     console.log(user);
    //     const userInfo = {
    //       displayName: data.name,
    //     };
    //     updateUser(userInfo)
    //       .then(() => {
    //         saveUser(data.name, data.email,data.password, data.role, currentDate);
    //         toast.success("User Added");
    //         reset();
    //       })
    //       .catch((err) => console.log(err));
    //   })
    //   .catch((err) => {
    //     toast.error("Email Already Exists");
    //     reset();
    //     console.log(err);
    //     setSignUPError(err.message);
    //   });

    const currentDate = new Date();
    saveUser(data.name, data.email, data.password, data.role, currentDate);
  };
  const saveUser = (name, email, password, role, currentDate) => {
    const user = { name, email, password, role: "user", currentDate };
    fetch("https://jahidserver.vercel.app/users", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          toast.success("User Added");
          reset();
        } else {
          setSignUPError(true)
          toast.error("Email Already Exists");
        }

        // addWebsite(email);
        // setCreatedUserEmail(email);
      });
  };

  const addWebsite = (email) => {
    fetch(`https://jahidserver.vercel.app/websites?email=${email}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then();
  };
  return (
    <div className="grid items-center relative w-full h-screen bg-slate-800">

      <form
        onSubmit={handleSubmit(handleAdduser)}
        className="w-full md:w-1/4 mx-auto p-4 bg-slate-700 rounded-md text-white  py-4 px-6"
      >
        <div className="grid grid-cols-1 md:grid-cols2">
          <div>
            <h2 className="text-center text-2xl">Live Server</h2>
          </div>
          <div className="text-left px-2 my-2">
            <label className="text-gray-400"> Name </label> <br />
            <input
              type="text"
              {...register("name", {
                required: "Name is Required",
              })}
              className="bg-gray-500 border-1 px-4 py-2 shadow-md rounded-md border-white w-full text-white focus:outline-none"
            />
            {errors.name && (
              <p className="text-red-500">{errors.name.message}</p>
            )}
          </div>
          <div className="text-left px-2 my-2">
            <label className={`${signUpError ? 'text-red-600' : 'text-gray-400'}`}>Email</label> <br />
            <input
              type="email"
              {...register("email", {
                required: "Email is Required",
              })}
              className={`border-1 bg-gray-500 px-4 py-2 shadow-md rounded-md border-white w-full  focus:outline-none`}
            />
            {errors.email && (
              <p className="text-red-500">{errors.email.message}</p>
            )}
          </div>
          <div className="text-left px-2 my-2">
            <label className="text-gray-400">Password</label> <br />
            <input
              type="password"
              {...register("password", {
                required: "Password is Required",
              })}
              className="bg-gray-500 border-1 px-4 py-2 shadow-md rounded-md border-white w-full text-white focus:outline-none"
            />
            {errors.password && (
              <p className="text-red-500">{errors.password.message}</p>
            )}
          </div>
        </div>
        <div className="px-2">
          <button
            type="submit"
            className="bg-blue-400 px-4 py-2 rounded-md text-white drop-shadow-lg w-full mt-2"
          >
            Register
          </button>
        </div>

      </form>
    </div>
  );
};

export default Register;
