import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../Layout/DashboardLayout";
import Home from "../Pages/Home/Home";
import Information from "../Pages/Informations/Information";
import AllUser from "../Pages/User/AllUser";
import User from "../Pages/User/User";
import Website from "../Pages/Website/Website";
import Login from "../Pages/Login/Login";
import PrivateRoutes from "./PrivateRoutes";
import Register from "../Pages/Register/Register";
import UpdatePassword from "../Pages/UpdatePassword/UpdatePassword";
import AdminRoute from "./AdminRoute";



export const router = createBrowserRouter([
    {
        path: '/',
        element: <Login></Login>
    },
    {
        path: '/login',
        element: <Login></Login>
    },
    {
        path: '/register',
        element: <Register></Register>,
    },
    {
        path: '/dashboard',
        element: <PrivateRoutes> <DashboardLayout></DashboardLayout> </PrivateRoutes>,
        // element: <h1>Temporary Unavailable</h1> ,
        children: [
            {
                path: '/dashboard',
                element: <Home></Home>
            },
            {
                path: '/dashboard/user',
                element: <AdminRoute><User></User></AdminRoute>
            },
            {
                path: '/dashboard/all-user',
                element: <AllUser></AllUser>
            },
            {
                path: '/dashboard/website',
                element: <Website></Website>
            },
            {
                path: '/dashboard/information',
                element: <Information></Information>
            },
            {
                path: '/dashboard/update-password',
                element: <UpdatePassword></UpdatePassword>
            },
        ]
    }
])