// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyB1mjSHyU4kQ5Zz3FMZucM_Qnws0cLftg8",
  authDomain: "jahid-server.firebaseapp.com",
  projectId: "jahid-server",
  storageBucket: "jahid-server.appspot.com",
  messagingSenderId: "663147622116",
  appId: "1:663147622116:web:38482b27371769f57b86f1",
  measurementId: "G-T7P8NNN0LH"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app;