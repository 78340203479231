import React from "react";
import { useLoaderData } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
import useAdmin from "../../hooks/useAdmin";
import { useContext } from "react";
import { AuthContext } from "../../Contexts/AuthProvider";
import { useQuery } from "react-query";
const AllUser = () => {
  const { user, removeUser } = useContext(AuthContext);
  const {
    data: users = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      // const res = await fetch(`https://rani-bhobani-server.vercel.app/alumnis?page=${page}&size=${size}`, {
      const res = await fetch(
        `https://jahidserver.vercel.app/users`
      );
      const data = await res.json();
      return data;
    },
  });

  const [isAdmin] = useAdmin(user?.email);

  const handleDelete = (id, email) => {
    // console.log(id);
    Swal.fire({
      title: "Do you want to Delete!",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(`https://jahidserver.vercel.app/users/${id}?email=${email}`, {
          method: "DELETE"
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.deletedCount > 0) {
              toast.success(`User deleted successfully`);
              refetch()
            }
          });
        refetch();
      } else if (result.isDenied) {
        Swal.fire("User is not Deleted", "", "info");
      }
    });
  };
  const handleAdmin = (email) => {
    // console.log(id);
    Swal.fire({
      title: "Do you want to Make an Admin!",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(`https://jahidserver.vercel.app/makeadmin?email=${email}`, {
          method: "put"
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.deletedCount > 0) {
              toast.success(`Admin Added Successfully`);
              refetch()
            }
          });
        refetch();
      } else if (result.isDenied) {
        Swal.fire("Denied!", "", "info");
      }
    });
  };

  return (
    <div className="w-full overflow-auto">
      <h3 className="text-headcolor">All User</h3>
      <table className="table table-auto w-full border-spacing-6 border-headcolor rounded-md shadow-md">
        <thead>
          <tr className="bg-white text-headcolor text-left">
            <th className="p-5">#</th>
            <th className="p-5">Name</th>
            <th className="p-5">Email</th>
            <th className="p-5">Password</th>
            {isAdmin ? <th>Action</th> : ''}
          </tr>
        </thead>
        <tbody>
          {users.reverse().map((user, i) => (
            <tr
              key={i}
              className="bg-white text-headcolor text-xs border text-left"
            >
              <td className="p-5">{i + 1}</td>
              <td className="p-5">{user.name}</td>
              <td className="p-5">{user.email}</td>
              <td className="p-5">{user.password}</td>
              <td className="p-5">
                <button
                  className="px-3 py-2 text-sm text-white bg-red-600 ml-2"
                  onClick={() => handleDelete(user._id, user.email)}
                >
                  Delete
                </button>
                {/* <button
                  className="px-3 py-2 text-sm text-white bg-blue-600 ml-2"
                  onClick={() => handleAdmin(user.email)}
                >
                  Make Admin
                </button> */}
              </td>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllUser;
