import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Contexts/AuthProvider";
import useToken from "../../hooks/useToken";
import Swal from "sweetalert2";
import useAdmin from "../../hooks/useAdmin";

const User = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { createUser, updateUser, user } = useContext(AuthContext);
  const [signUpError, setSignUPError] = useState("");
  const [createdUserEmail, setCreatedUserEmail] = useState("");
  const [token] = useToken(createdUserEmail);
  const navigate = useNavigate();
  const currentDate = new Date();

  const handleAdduser = (data) => {
    //   createUser(data.email,data.password)
    //   .then(result => {
    //       const user = result.user;
    //       console.log(user);
    //       toast.success('User Created Successfully.')
    //       const userInfo = {
    //           displayName: data.name
    //       }
    //       updateUser(userInfo)
    //           .then(() => {
    //               saveUser(data.name, data.email,data.role);
    //               reset()
    //           })
    //           .catch(err => console.log(err));
    //   })
    //   .catch(err =>{
    //       toast.error('Email Already Exists');
    //       reset();
    //       console.log(err);
    //       setSignUPError(err.message);

    //   })
    saveUser(data.name, data.email, data.password, data.role, currentDate);
    reset();
  };
  const saveUser = (name, email, password, role, currentDate) => {
    const user = { name, email, password, role: "user", currentDate };
    fetch("https://jahidserver.vercel.app/users", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        addWebsite(email);
        toast.success("user added sucessfully");
        setCreatedUserEmail(email);
      });
  };

  const addWebsite = (email) => {
    fetch(`https://jahidserver.vercel.app/websites?email=${email}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then();
  };


  return (
    <div>
      <form
        onSubmit={handleSubmit(handleAdduser)}
        className="bg-white text-headcolor py-4 px-6"
      >
        <div className="grid grid-cols-1 md:grid-cols2">
          <div className="text-left px-2 my-2">
            <label className="text-headcolor text-sm"> Name </label> <br />
            <input
              type="text"
              {...register("name", {
                required: "Name is Required",
              })}
              className="bg-white border-[1px] px-4 py-2 rounded-md border-headcolor w-full text-headcolor text-sm focus:outline-none"
            />
            {errors.name && (
              <p className="text-red-500">{errors.name.message}</p>
            )}
          </div>
          <div className="text-left px-2 my-2">
            <label className="text-headcolor text-sm">Email</label> <br />
            <input
              type="email"
              {...register("email", {
                required: "Email is Required",
              })}
              className="bg-white border-[1px] px-4 py-2 rounded-md border-headcolor w-full text-headcolor text-sm focus:outline-none"
            />
            {errors.email && (
              <p className="text-red-500">{errors.email.message}</p>
            )}
          </div>
          <div className="text-left px-2 my-2">
            <label className="text-headcolor text-sm">Password</label> <br />
            <input
              type="password"
              {...register("password", {
                required: "Password is Required",
              })}
              className="bg-white border-[1px] px-4 py-2 rounded-md border-headcolor w-full text-headcolor text-sm focus:outline-none"
            />
            {errors.password && (
              <p className="text-red-500">{errors.password.message}</p>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="bg-btncolor px-4 py-2 rounded-md text-white text-sm drop-shadow-lg mt-2 ml-2"
        >
          Add User
        </button>
      </form>
    </div>
  );
};

export default User;
