import React, { useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { FaBars, FaWindowClose } from "react-icons/fa";
import { AuthContext } from '../../../Contexts/AuthProvider';
import { FaSignOutAlt } from "react-icons/fa";
const Header = () => {
  const [open, setOpen] = useState(false);

  const { user, logOut } = useContext(AuthContext);
  const handleLogOut = () => {
    logOut()
      .then(() => { })
      .catch(err => console.log(err));
  }

  return (
    <header className='z-[999]'>
      <nav className="py-5 md:flex md:items-center md:justify-between md:place-items-center">
        <div className="flex justify-between place-items-center">
          <Link to={'/'} className="text-xl font-bold text-headcolor ">
            LiveTool
          </Link>
          <span
            onClick={() => setOpen(!open)}
            className="md:hidden block text-white"
          >
            {open ? <FaWindowClose></FaWindowClose> : <FaBars></FaBars>}
          </span>
        </div>
        <ul
          className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static md:bg-transparent  left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? "top-20 bg-[#ffffff] z-[999] sm:drop-shadow-md sm:rounded-md md:drop-shadow-none py-4 w-[80%]  rounded-md " : "top-[-490px]"
            }`}
        >
          {
            user ? <>
              <li>
                <Link to={'/dashboard/update-password'} className="text-sm text-headcolor ">
                  Update Password
                </Link>
              </li>
              <li>
                <button onClick={handleLogOut} className="px-4 py-2 text-red-500 text-sm flex gap-2 items-center">
                  <span>Logout</span> <FaSignOutAlt className='text-red-500'></FaSignOutAlt>
                </button>
              </li>

            </>
              :
              <></>
          }
        </ul>
      </nav>
    </header>
  );
};

export default Header;